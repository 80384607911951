import { h } from 'vue'
/**
 * 高阶组件
 * @param {Component} WrappedComponent 需要包装的组件
 * @returns 
 */
export function HOC(WrappedComponent, options = {}) {
  return {
    props: typeof WrappedComponent === 'function' ? WrappedComponent.options.props : WrappedComponent.props,
    name: `${WrappedComponent.name}Hoc`,
    render() {
      const slots = Object.keys(this.$slots)
        .reduce((arr, key) => arr.concat(this.$slots[key]), [])
      // 手动更正 context
        .map(vnode => {
          vnode.context = this._self
          return vnode
        })
      
      const { isCard } = options

      // 向WrappedComponent传递额外的属性
      const wrappedProps = {
        ...this.$props,
        isCard // 添加 isCard 属性
      }
      // console.log(options, 'options--')

      return h(WrappedComponent, {
        ...this.$attrs,
        ...(isCard ? wrappedProps : this.$props),
      }, {
        default: () => slots
      })
    }
  }
}
