<template>
  <div>
    <StaticImage
      v-bind="$props"
      :isBottomFloat="isBottomFloat"
      @baseImageMounted="baseImageMounted()"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import StaticImage from '../static-image/index.vue'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { bottomSticky } from 'public/src/pages/components/ccc/base/sticky/bottom.js'
import { cccxEventBus } from 'public/src/pages/components/ccc/common/utils.js'

export default defineComponent({
  name: 'StaticImageCardController',
  components: {
    StaticImage
  },
  directives: {
    bottomSticky
  },
  emits: ['baseImageMounted'],
  props: commonProps,
  data() {
    const {
      id,
      styleType = '',
    } = this.propData
    return {
      isBottomFloat: false,
      id,
      styleType,
    }
  },
  computed: {
    metaData() {
      return this.propData?.props?.metaData || {}
    },

  },

  mounted() {
    cccxEventBus?.on?.(`float-${this.styleType}-${this.id}`, result => {
      if (result?.type === 'static') {
        this.isBottomFloat = false
      } else {
        this.isBottomFloat = true
      }
    })
  },
  beforeUnmount() {
    cccxEventBus?.off?.(`float-${this.styleType}-${this.id}`)
  },
  methods: {
    baseImageMounted() {
      this.$emit('baseImageMounted')
    }
  },
})
</script>
